/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;900&family=Open+Sans:wght@400;500;700&display=swap');
/* variables Styles */
@import './variables';
/* Custom Styles */
@import './custom';
/* Colors Styles */
@import './colors';
/* forms Styles */
@import './forms';
/* global Styles */
@import './globalStyle';