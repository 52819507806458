@import "../scss/colors";

:root {
  --body-font-family: "Open Sans", sans-serif;
  --head-font-family: "Open Sans", sans-serif;
  --head-font-family2: "Montserrat", sans-serif;
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --head-font-weight: 700;
  --body-line-height: 1.5;
  --body-color: #737491;
  --body-bg: #fff;
}

// font-family: 'Montserrat',sans-serif;
// font-family: 'Open Sans',sans-serif;

body {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--body-color);
  // background-color: rgba(255, 107, 107, 0.4);
  // background-color: rgba(255, 100, 100, 0.6);

  background-color: #fce5ea;
  // background-image: linear-gradient(
  //   -129deg,
  //   #ff6464 0%,
  //   #fc8383 50%,
  //   #f7ce68 100%
  // );
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
  color: #333;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s linear;

  &:hover {
    color: $main-color !important;
  }
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* Buttons */

.btn-link {
  text-decoration: none;
  // color: var(--body-color) !important;
  // font-weight: 600 !important;
}

.btn:focus,
button:focus {
  outline: 0;
  box-shadow: unset !important;
}

.btn-second {
  color: #fff !important;

  // &:hover {
  //     color: #fff !important;
  // }
}

.btn-primary {
  color: #fff !important;

  &:hover {
    color: #fff !important;
  }
}

.btn-outline-primary {
  &:hover {
    color: #fff !important;
  }
}

.btn-outline-gray-800 {
  &:hover {
    color: #fff !important;
  }
}

.btn-check:focus + .btn-primary,
.btn-primary:active,
.btn-primary:focus {
  box-shadow: unset !important;
}

.dropdown-toggle {
  &::after {
    border: none !important;
  }
}

.navbar-toggler {
  color: #465963 !important;
  border-radius: 0;
  transition: 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: unset !important;
  }
}

.card-shadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    // box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25),
    //     0 0 7px rgba(0, 0, 0, 0.22);
    box-shadow: 6px 11px 41px -28px #a99de7;
  }
}

.shadow-sm {
  transition: 0.3s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.zoom-hover {
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: scale(1.1);
  }
}

/* fon size */
.fs-xs {
  font-size: 0.75rem !important;
}

.fs-md {
  font-size: 0.9375rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Tooltip */
[tooltip] {
  position: relative;
  cursor: pointer;
  z-index: 99;
}

[tooltip]:hover::before {
  content: attr(tooltip);
  position: absolute;
  bottom: -41px;
  display: inline-block;
  padding: 5px 8px;
  border-radius: 4px;
  background: $main-color;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  white-space: nowrap;
}
[tooltip]:hover::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 9px solid transparent;
  border-bottom: 9px solid $main-color;
}
