@import "../../../scss/colors";

.cartWidget {
  width: 350px;
  float: right;
  height: 100%;
  background-color: #fff;
  padding-bottom: 15px;
  transition: 0.3s;
  height: 100vh;
  margin-top: 2rem;

  // Large devices (desktops, 992px and up)
  @media (max-width: 992px) {
    visibility: hidden;
    transition: 0.3s;

    transform: translateX(300px);

    &.show {
      visibility: visible;
      position: fixed;
      top: 55px;
      height: 100%;
      right: 10px !important;
      z-index: 999;
      transform: translateX(0);
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: -1px 1px 7px rgba(0, 0, 0, 0.3);
    }
  }

  .cartWidget__body {
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 250px;
    max-height: 300px;
    scroll-behavior: smooth;
    transition: 0.3s;

    /* scrollbar **/
    &::-webkit-scrollbar {
      width: 0.2em;
    }

    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: none;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $main !important;
      }
    }
  }

  .widget__item {
    user-select: none;
    .widget__item__img {
      width: 65px;
      height: 65px;

      img {
        width: 100%;
      }
    }
    .widget__item__heading {
      h6 {
        font-size: 15px;
      }
    }
  }

  .payment__Item {
    cursor: pointer;
    &:hover {
      & .payment__icon {
        border-color: $main !important;
        color: $main !important;
      }
    }

    &.active {
      .payment__icon {
        background-color: $main !important;
        border-color: $main !important;
        color: white !important;
      }
    }
    .payment__icon {
      height: 70px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      transition: 0.3s;
      position: relative;
      .check__box {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: 2;
      }
      .check__input {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 33;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}
