//== Colors
//
// Gray and brand colors to use across the Theme.

// Grayscale
$white: #fff !default;
$dark-light: #465963 !default;
$gray-100: #f7f7fc !default;
$gray-200: #f3f3f9 !default;
$gray-300: #e9e9f2 !default;
$gray-400: #dfdfeb !default;
$gray-500: #9e9fb4 !default;
$gray-600: #737491 !default;
$gray-700: #5a5b75 !default;
$gray-800: #4a4b65 !default;
$gray-900: #37384e !default;
$black: #000 !default;

// Brand colors
$primary: #ff6464 !default;
$main-color: #ff6464;
$second: #ffbb2e;

$secondary: #f1f7fc !default;
$info: #6a9bf4 !default;
$success: #16c995 !default;
$warning: #ffb15c !default;
$danger: #f74f78 !default;
$light: $white !default;
$dark: $gray-900 !default;

// color-variables
$main-color: $primary !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// Adding new color to default Bootstrap's $theme-colors map
$theme-colors: ('primary': $primary,
    'second': $second,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
    'dark-light': $dark-light,
    'dark': $dark) !default;

// Faded colors map
$theme-faded-colors: ('primary': rgba($primary, .08),
    'second': rgba($second, .08),
    'success': rgba($success, .08),
    'info': rgba($info, .08),
    'warning': rgba($warning, .08),
    'danger': rgba($danger, .08),
    'light': rgba($light, .08),
    'gray-100': rgba($gray-100, .08),
    'gray-200': rgba($gray-200, .08),
    'gray-300': rgba($gray-300, .08),
    'gray-400': rgba($gray-400, .08),
    'gray-500': rgba($gray-500, .08),
    'gray-600': rgba($gray-600, .08),
    'gray-700': rgba($gray-700, .08),
    'gray-800': rgba($gray-800, .08),
    'gray-900': rgba($gray-900, .08),
    'dark-light': rgba($dark-light, .08),
    'dark': rgba($dark, .08)) !default;

// Gradient
$gradient-from-color: $primary !default;
$gradient-to-color: $info !default;

$gradient: linear-gradient(to right, $gradient-from-color 0%, $gradient-from-color 30%, $gradient-to-color 100%) !default;


// User selection color
$user-selection-color: rgba($primary, .22) !default;


// Spacing

$spacer: 1rem !default;

// Spacing between columns
$grid-gutter-width: 1.875rem !default;

$spacers: (0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 5,
    7: $spacer * 6,
    'grid-gutter': $grid-gutter-width) !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-300 !default;
$border-light-color: rgba($white, .14) !default;

$border-radius: .75rem !default;
$border-radius-lg: 1rem !default;
$border-radius-sm: .5rem !default;
$border-radius-xs: .375rem !default;

$box-shadow: 0 0 .625rem -.1875rem rgba($black, .13) !default;
$box-shadow-lg: -.0625rem 0 .625rem 0 rgba($black, .07),
.3125rem 1.25rem 2.5rem 0 rgba($black, .04) !default;
$box-shadow-sm: 0 0 .625rem -.1875rem rgba($black, .13) !default;
$box-shadow-active: .1875rem .1875rem 0.625rem -.125rem rgba($black, .09) !default;
$box-shadow-inset: inset 0 1px 2px transparent !default;

// Custom options: Font Size
$font-size-base: 1rem !default;
$font-size-xl: ($font-size-base * 1.25) !default;
$font-size-lg: ($font-size-base * 1.125) !default;
$font-size-md: ($font-size-base * .9375) !default;
$font-size-sm: ($font-size-base * .875) !default;
$font-size-ms: ($font-size-base * .8125) !default;
$font-size-xs: ($font-size-base * .75) !default;

// Custom options: Size and Color
$form-option-size-width: 2.75rem !default;
$form-option-color-width: 1.625rem !default;
$form-option-padding-x: .375rem !default;
$form-option-border-width: $border-width !default;
$form-option-border-color: $border-color !default;
$form-option-border-radius: $border-radius-sm !default;
$form-option-hover-border-color: darken($border-color, 8%) !default;
$form-option-active-border-color: $primary !default;
$form-option-font-size: $font-size-md !default;
$form-option-font-weight: normal !default;
$form-option-color: $gray-700 !default;
$form-option-hover-color: $gray-800 !default;
$form-option-active-color: $white !default;
$form-option-active-bg: $primary !default;
$form-option-transition: color .2s ease-in-out,
border-color .2s ease-in-out !default;