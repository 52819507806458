.bannar {
  margin-top: 2rem;

  //special sidebar style in mobile screen
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
  }
  .container {
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
  .bannar__card {
    min-height: 170px;
    .bannar__card__img {
      position: relative;
      width: 160px;
      top: -50px;
    }
    .btn {
      width: 75%;
      margin-top: -50px;
    }
  }
}
