@import "../../../scss/colors";

.navbar {
  height: 90px;
  z-index: 999;

  .navbar-brand {
    position: relative;
    width: 20%;

    img {
      max-height: 60px !important;
    }

    .sub-logo {
      margin-left: -10px;
      font-size: 1.3rem;
      transition: color 0.3s ease-in;
    }

    small {
      position: absolute;
      top: 9px;

      font-size: 14px;
      transition: 0.2s;
    }

    &:hover {
      .sub-logo {
        color: #465963 !important;
      }
    }
  }

  .btn__toggle {
    // color: $main-color !important;
    // box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.3);
    @media (max-width: 1600px) {
      display: block !important;
    }

    &:hover,
    &:active,
    &:focus {
      color: $orange !important;
    }
  }

  .navbar-toggle {
    padding: 0.75rem 0.75rem;
    font-size: 1rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    transition: box-shadow 0.15s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: none;
      box-shadow: unset;
    }
  }

  .navbar-tool {
    position: relative;
    color: #5a5b75;
    cursor: pointer;

    .navbar-tool-icon-box {
      position: relative;
      // width: 2.75rem;
      // height: 2.75rem;
      font-size: 1.375rem;
      text-align: center;

      span {
        font-size: 0.9rem;
      }
    }

    .navbar-tool-badge {
      position: absolute;
      top: -5px;
      right: -15px;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $main;
      color: #fff;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  ul.navbar-nav {
    li {
      padding: 0.5rem;
      overflow: hidden;
    }

    .nav-link {
      position: relative;
      font-size: 1.2rem;
      text-transform: capitalize;
      padding: 0;

      &::after {
        content: "";
        position: absolute;
        bottom: -5rem;
        left: 0.5rem;
        height: 7px;
        width: 100%;
        background-color: #eedfdf9f;
        z-index: -1;
        transition: 0.3s;
      }

      &.active {
        color: $main;
        font-weight: bold;

        &::after {
          bottom: 0.5rem;
        }
      }
    }
  }

  .cartItems {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.navbar-shadow {
  box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.222);
}

.sidebar__nav {
  padding: 0;

  ul {
    list-style: none;
    padding: 0;
  }

  .nav-link {
    position: relative;
    padding: 30px;
    width: 100%;
    background-color: $main;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 10px;
    padding-left: 2rem !important;
    transition: 0.3s ease-in-out;

    &:hover {
      color: #fff !important;
      font-weight: bold;
      letter-spacing: 1.5px;

      & .nav-link-icon {
        font-size: 5rem;
        bottom: 6px;
      }
    }

    .nav-link-icon {
      position: absolute;
      right: 0;
      bottom: -20px;
      font-size: 3rem;
      color: #fff;
      transition: 0.3s;
    }
  }
}

.search__bar {
  width: 40%;
  height: 100%;
  z-index: 1090;

  .form-control {
    border: 0 !important;
    background-color: transparent !important;
    font-size: 1rem;
    box-shadow: none !important;
  }
}
