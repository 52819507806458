@import "../../scss/colors";

.product__card {
  user-select: none;
  .product__size {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.3rem;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      transform: translateY(-5px);
    }

    .btn-size-check {
      // position: absolute;
      visibility: hidden;
      display: none;

      &:checked ~ .btn {
        background-color: $main;
        color: #fff !important;
      }
    }
  }

  .card__btn {
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      transform: translateY(-5px);
    }
    .card__btn__icon {
      font-size: 3rem;
    }
  }
}
